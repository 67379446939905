const $ = jQuery;

$(() => {
  initBanners();
  initConsultantsSlider();
  initLocationList();
  initHeaderWindows();
  initMenu();
  initRolesTab();
  initModalOpen();
  initModalClose();
  initRegistrationSubmit();
  initSelect();
  initSideMenu();
  initHookahOrder();
  initPhotoGallery();
  initTabs();
  initRecoveryForm();
  initCitySelect();
  initPhotoGallery2();
  initTestsSlider();
  initVacancies();
  initInputClear();
  initRadioGroup();
  initClickOutside();
  initFormMap();
  initReviewModalForm();
  initRating();
  initCaptureSubmit();
  initDateSelect();
  initOrderModal();
  initScrollTop();

  $('[data-fancybox]').fancybox({
    toolbar: false,
    infobar: false
  });
});

const initBanners = () => {
  $('.banners').slick({
    arrows: false,
    dots: true
  });
};

const initConsultantsSlider = () => {
  $('.consultants').slick({
    arrows: true,
    dots: false,
    slidesToShow: 4,
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 729,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  });
};

const initLocationList = () => {
  $('.locations__toggle-list').click((e) => {
    e.preventDefault();
    $(e.target).closest('.locations').toggleClass('is-active');
  });
};

const initHeaderWindows = () => {
  $('.js-md-window-open').click((e) => {
    e.preventDefault();
    const target = $(e.target).closest('.js-md-window-open').attr('data-target');
    $(target).toggleClass('is-active');
    $(`.header__md-window.is-active:not(${target})`).removeClass('is-active');
  });

  $('.js-md-window-close').click((e) => {
    e.preventDefault();
    const target = $(e.target).closest('.header__md-window');
    $(target).removeClass('is-active');
  });

  $('.header__top').click((e) => {
    const mdWindowBtn = $(e.target).closest('.js-md-window-open');
    if (mdWindowBtn.length === 0) {
      $('.header__md-window.is-active').removeClass('is-active');
    }
  });
};

const initMenu = () => {
  $('.js-open-menu').click((e) => {
    e.preventDefault();
    $('.mobile-menu').addClass('is-active');
  });

  $('.js-close-menu').click((e) => {
    e.preventDefault();
    $('.mobile-menu').removeClass('is-active');
  });
};

const initSideMenu = () => {
  $('.js-open-side-menu').click((e) => {
    e.preventDefault();
    const target = $(e.target).attr('data-target')
    $(target).addClass('is-active');
  });

  $('.js-close-side-menu').click((e) => {
    e.preventDefault();
    $(e.target).closest('.side-menu').removeClass('is-active');
  });
};

const initRolesTab = () => {
  $('.reg__roles-btn input').change((e) => {
    const input = $(e.target);
    if (input.is(':checked')) {
      const btn = input.closest('.reg__roles-btn');
      btn.addClass('is-active').siblings().removeClass('is-active');

      const descr = btn.attr('data-descr');
      $(descr).addClass('is-active').siblings().removeClass('is-active');

      const value = btn.find('span').html();  
      input.closest('.reg__roles-nav').find('.reg__roles-nav-toggle span').html(value);
      input.closest('.reg__roles-nav').removeClass('is-active');
    }
  });

  $('.reg__roles-nav-toggle').click((e) => {
    const target = $(e.target);
    target.closest('.reg__roles-nav').toggleClass('is-active');
  });

  $(document).click(e => {
    const nav = $('.reg__roles-nav')
    if (!!nav.length && !nav[0].contains(e.target)) {
      nav.removeClass('is-active');
    }
  });
};

const initRegistrationSubmit = () => {
  $('.reg').submit(e => {
    e.preventDefault();
    $('#reg-thanks').addClass('is-active');
  });
};

const initModalOpen = () => {
  $('.js-modal-open').click(e => {
    e.preventDefault();
    const modal = $(e.target).attr('data-modal');
    $(modal).addClass('is-active');
  });
};

const initModalClose = () => {
  $('.js-modal-close').click(e => {
    e.preventDefault();
    $(e.target).closest('.modal').removeClass('is-active');
  });

  $(document).keyup(function(e) {
    if (e.key === "Escape") {
      $('.modal.is-active').removeClass('is-active');
    }
  });
};

const initSelect = () => {
  $('.select__toggle').click(e => {
    e.preventDefault();
    $(e.target).closest('.select').toggleClass('is-active');
  });

  $('.select__option').click(e => {
    const target = $(e.target);
    const val = target.html();
    const select = target.closest('.select');
    target.addClass('is-active').siblings().removeClass('is-active');
    select.find('.select__toggle span').html(val);
    select.removeClass('is-active');
  });
};

const initHookahOrder = () => {
  $('.hookah-order__form').submit(e => {
    e.preventDefault();
    $('.hookah-order').addClass('is-submitted');
  });
};

const initPhotoGallery = () => {
  $('.photo-gallery').slick({
    arrows: true,
    dots: false,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 729,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  });
};

const initTabs = () => {
  $('.tabs__nav-item').click(e => {
    const item = $(e.target);
    const target = item.attr('data-target');
    const tabsNav = item.closest('.tabs__nav');

    tabsNav.find('.tabs__nav-toggle span').html(item.html());
    tabsNav.removeClass('is-active');
    item.addClass('is-active').siblings().removeClass('is-active');
    $(target).addClass('is-active').siblings().removeClass('is-active');
  });

  $('.tabs__nav-toggle').click(e => {
    $(e.target).closest('.tabs__nav').toggleClass('is-active');
  });
};

const initRecoveryForm = () => {
  $('.recovery__form').submit(e => {
    e.preventDefault();
    $(e.target).closest('.recovery').addClass('has-result');
  });
};

const initCitySelect = () => {
  $('.city-check__submit').click(e => {
    $('body').removeClass('city-check-active');
  });

  $('.city-check__select').click(e => {
    $('body').removeClass('city-check-active');
    $('.city-select').addClass('is-active');
  });

  $('.city-select__close').click(e => {
    $('.city-select').removeClass('is-active');
  });
};

const initPhotoGallery2 = () => {
  $('.photo-gallery-2__main').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    asNavFor: '.photo-gallery-2__nav',
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          dots: true
        }
      }
    ]
  });
  $('.photo-gallery-2__nav').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: '.photo-gallery-2__main',
    dots: false,
    centerMode: false,
    focusOnSelect: true,
    infinite: false
  });
};

const initTestsSlider = () => {
  $('.tests-slider').slick({
    arrows: true,
    dots: false,
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 729,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  });
};

const initVacancies = () => {
  $('.search-line__view, .filter-and-sort__view').click(e => {
    e.preventDefault();
    $('.vacancies-section').toggleClass('map-view');
  });

  $('.vacancies-map__filter-toggle').click(e => {
    e.preventDefault();
    $('.vacancies-map__filter').toggleClass('is-active');
  });

  $('.vacancies-map__filter-close').click(e => {
    e.preventDefault();
    $('.vacancies-map__filter').removeClass('is-active');
  });
};

const initInputClear = () => {
  $('.input-with-controls input[type="text"]').keyup(e => {
    const val = e.target.value;
    const parent = $(e.target).closest('.input-with-controls');
    if (val !== '') {
      parent.addClass('is-active');
    } else {
      parent.removeClass('is-active');
    }
  });

  $('.input-with-controls__btn').click(e => {
    const parent = $(e.target).closest('.input-with-controls');
    const input = parent.find('input[type="text"]');
    parent.removeClass('is-active');
    input.val('');
  });
};

const initRadioGroup = () => {
  $('.radio-group__toggle').click(function(e) {
    e.preventDefault();
    $(this).closest('.radio-group').toggleClass('is-active');
  });

  $('.radio-group input').change(function() {
    const isChecked = $(this).is(':checked');
    if (isChecked) {
      const parent = $(this).closest('.radio-group__item');
      const value = parent.find('span').html();
      parent.addClass('is-active').siblings().removeClass('is-active');
      parent.closest('.radio-group').removeClass('is-active').find('.radio-group__toggle span').html(value);
    }
  });
};

const initClickOutside = () => {
  document.addEventListener('click', (e) => {
    const target = e.target;
    const activeElement = document.querySelector('.click-outside.is-active');
    if (activeElement && !activeElement.contains(target)) {
      activeElement.classList.remove('is-active');
    }
  });
};

const initFormMap = () => {
  $('.form__on-map-btn').click(e => {
    e.preventDefault();
    $('.form__map').toggleClass('is-active');
  });
  
  $('.form__map-close').click(e => {
    e.preventDefault();
    $('.form__map').removeClass('is-active');
  });
};

const initReviewModalForm  = () => {
  $('.review-modal form').submit(e => {
    e.preventDefault();
    $(e.target).closest('.review-modal').addClass('show-thanks');
  });
};

const initRating = () => {
  $('.rating--editable i').click(function(e) {
    $(this).closest('.rating').find('i').addClass('is-active');
    $(this).nextAll().removeClass('is-active');
  });
};

const initCaptureSubmit = () => {
  $('.capture').submit(e => {
    e.preventDefault();
    $('#thanks-modal').addClass('is-active');
  });
};

const initDateSelect = () => {
  $('.date-select__toggle').click(function(e) {
    $(this).closest('.date-select').toggleClass('is-active');
  });

  $('.date-select__close, .date-select__footer-close').click(function(e) {
    $(this).closest('.date-select').removeClass('is-active');
  });
};

const initOrderModal = () => {
  $('.order').submit(function(e) {
    e.preventDefault();
    $(this).closest('.modal').removeClass('is-active');
    $('#thanks-modal-2').addClass('is-active');
  })
};

const initScrollTop = () => {
  $(window).scroll(function(e) {
    const scrollTop = $(window).scrollTop();
    if (scrollTop > 100) {
      $('.scroll-top').addClass('is-active');
    } else {
      $('.scroll-top').removeClass('is-active');
    }
  });

  $('.scroll-top').click(function(e) {
    e.preventDefault();
    $('html, body').animate({scrollTop: 0}, 500);
  });
};